<template>
  <!-- table -->
  <xm-flex>
    <xm-flex-item>
      <xm-total-count>{{`套餐订单列表(${totalCount}条)`}}</xm-total-count>
    </xm-flex-item>
    <xm-flex-item :flex="1">
      <xm-table-plus :query-data="queryData" ref="table">
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="member.name" label="用户名"></el-table-column>
        <el-table-column prop="institution.name" label="店铺名"></el-table-column>
        <el-table-column label="套餐">
          <template #default="scope">
            <div v-if="scope.row.setmealType === 1">标准版</div>
            <div v-else-if="scope.row.setmealType === 2">专业版</div>
            <div v-else-if="scope.row.setmealType === 3">设计服务</div>
          </template>
        </el-table-column>
        <el-table-column prop="outTradeNo" label="订单编号"></el-table-column>
        <el-table-column prop="fee" label="支付费用（元）"></el-table-column>
        <el-table-column prop="years" label="时长（年）"></el-table-column>
        <el-table-column prop="plusYears" label="赠送时长（年）"></el-table-column>
        <el-table-column prop="addTime" label="时间" width="150"></el-table-column>
      </xm-table-plus>
    </xm-flex-item>
  </xm-flex>
</template>
<script>
import { ElTableColumn } from 'element-plus'
import axios from '@/common/utils/axios'
import { onMounted, ref } from 'vue'

function table () {
  /**
   * 表单信息
   */
  const table = ref(null)
  const totalCount = ref(0)
  /**
   * 查询表单列表
   */
  const queryData = (page, pageSize) => {
    return new Promise(resolve => {
      axios('/manager/platform/setmeal/order/list', {
        page,
        pageSize
      })
        .then(res => {
          totalCount.value = res.totalCount
          table.value.judgePosition(
            res.platformSetmealOrderList,
            res.totalCount
          )
          resolve({
            list: res.platformSetmealOrderList,
            totalCount: res.totalCount
          })
        })
        .catch(_ => {})
    })
  }
  onMounted(async () => {
    table.value.reload()
  })

  return {
    table,
    queryData,
    totalCount
  }
}

export default {
  components: { ElTableColumn },

  setup () {
    const t = table()
    return {
      ...t
    }
  }
}
</script>
